import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    rtl: true,
    options: { customProperties: true },
    themes: {
      light: true,
      light: {
        primary: {
          base: '#0092bc',
          lighten1: '#18a4cc',
          lighten2: '#2196f3',
          lighten5: '#d2ebf2',
        },
        secondary: '#cbf2fd',
        accent: '#f9fafd',
        success: {
          base: '#defcf1',
        },
        yellow: {
          base: '#ff9800',
          lighten1: '#ffc107',
          lighten2: '#ffeb3b',
          lighten5: '#fff6df',
        },
        error: {
          base: '#eb5757',
          lighten5: '#ffeded',
        },
        gray: {
          base: '#909ab0',
          lighten1: '#ebeff4',
        },
        black: '#142140',
      },
    },
  },
});
