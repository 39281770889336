<template>
  <v-list flat color="white">
    <v-list-item-group>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="`${item.link}`"
        class="font-weight-medium px-7 px-lg-4 mx-lg-6"
        :class="item.title == $route.name.toLowerCase() ? 'active' : ''"
      >
        <v-list-item-icon class="my-4 mr-3">
          <v-img height="24" max-width="24" :src="getImage(item.title == $route.name.toLowerCase() ? item.iconB : item.icon)" class="icon">
          </v-img>
        </v-list-item-icon>
        <v-list-item-content class="py-2">
          <v-list-item-title class="text-lg-body-2 text-body-1 black--text">{{ $t(`menu.items.${item.title}`) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: 'dashboard', link: '/', icon: 'home', iconB: 'homeB' },
        { title: 'portfolio', link: '/portfolio/overview', icon: 'portfolio', iconB: 'portfolioB' },
        { title: 'wallet', link: '/wallet/overview', icon: 'wallet', iconB: 'walletB' },
        { title: 'stock', link: '/stock', icon: 'stoc', iconB: 'stocB' },
        // { title: 'Transactions', link: '/transactions', icon: 'trans', iconB: 'transB' },
      ],
    };
  },
  methods: {
    getImage(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
  },
};
</script>

<style lang="scss">
#app .active {
  background: var(--v-accent-base);
  border-radius: 16px;
  .icon {
    filter: invert(40%) sepia(98%) saturate(2375%) hue-rotate(165deg) brightness(91%) contrast(101%);
  }
  .v-list-item__title {
    color: var(--v-primary-base) !important;
  }
}
@media screen and (max-width: 1263px) {
  .v-list-item {
    border-bottom: 1px solid #ebeff4;
  }
}
</style>
