import '@/plugins/axios';

const api = '/customer/api/v1/';

export default {
  state: {
    summaryList: { portfolio: { stocks: [] }, wallet: {} },
    performanceList: [],
    composition: {},
  },
  actions: {
    getSummary(context) {
      return axios.get(`${api}summary`).then((res) => {
        context.commit('GET_SUMMARY', res.data);
      });
    },
    setSummary: (context) => {
      context.commit('GET_SUMMARY', { portfolio: { stocks: [] }, wallet: {} });
    },
  },
  mutations: {
    GET_SUMMARY: (state, payload) => {
      state.summaryList = payload;
      return state.summaryList;
    },
  },
  getters: {
    summaryList(state) {
      return state.summaryList;
    },
  },
};
