import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/wallet/:tab',
      name: 'Wallet',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Wallet.vue'),
    },
    {
      path: '/portfolio/:tab',
      name: 'Portfolio',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Portfolio.vue'),
    },
    {
      path: '/stock',
      name: 'Stock',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/StockList.vue'),
    },
    {
      path: '/stock/:stockId',
      name: 'Stock item',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Stock.vue'),
    },
    {
      path: '/transactions',
      name: 'Transaction',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/TransactionList.vue'),
    },
    {
      path: '/referral',
      name: 'Referral system',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/Refarral.vue'),
    },
    {
      path: '/news',
      name: 'News',
      meta: {
        layout: 'Main',
        auth: true,
      },
      component: () => import('@/views/NewsList.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '/sign-up',
      name: 'Sign-up',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Registration.vue'),
    },
    {
      path: '/otp/:page',
      name: 'Otp',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Otp.vue'),
    },
    {
      path: '/info',
      name: 'Personal information',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Info.vue'),
    },
    {
      path: '/risk',
      name: 'Portfolio possition',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Risk.vue'),
    },
    {
      path: '/document',
      name: 'Document',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Document.vue'),
    },
    {
      path: '/question/:step',
      name: 'Question',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Question.vue'),
    },
    {
      path: '*',
      redirect: { name: 'Home' },
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const currentUser = sessionStorage.getItem('jwt_token');
  const requireAuth = to.matched.some((record) => record.meta.auth);

  if (requireAuth && !currentUser) {
    next({ name: 'Login', query: { from: to.fullPath } });
  } else {
    next();
  }
});

export default router;
