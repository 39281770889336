<template>
  <v-card flat tile min-width="256" min-height="100vh" color="white" class="card">
    <div class="logo ms-6 mt-6 mb-15">
      <v-img max-height="80" max-width="108" contain src="@/assets/img/logo.svg" alt="logo"></v-img>
    </div>
    <!-- <div class="gray--text ms-6 mb-5 mt-15">{{ $t('menu.investing') }}</div> -->
    <MenuList />
    <!-- <v-divider></v-divider>
    <div class="gray--text ms-6 mb-5 mt-6">{{ $t('menu.more') }}</div>
    <MenuListMore /> -->
  </v-card>
</template>

<script>
import MenuList from './MenuList.vue';
// import MenuListMore from './MenuListMore.vue';

export default {
  components: {
    MenuList,
    // MenuListMore,
  },
};
</script>

<style scoped>
.card {
  margin-top: -84px;
}
</style>
