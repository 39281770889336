<template>
  <v-window v-model="step" :touchless="false" style="overflow-y: auto">
    <v-window-item :value="0">
      <v-btn class="mb-2" @click="closeDrawer" text color="gray">
        <v-icon left>mdi-chevron-left mdi-24px</v-icon>{{ $t('btn.back') }}
      </v-btn>
      <div class="px-7 f18 font-weight-bold">{{ $t('setting.title') }}</div>
      <v-list flat color="white">
        <v-list-item-group>
          <v-list-item v-for="(i, j) in $t(`setting.tabs`)" :key="i" @click="step = j + 1" link class="px-7 px-lg-4 mx-lg-6">
            <v-list-item-icon class="my-4 mr-3">
              <v-img height="24" max-width="24" class="icon" :src="getIcon(icons[j])"></v-img>
            </v-list-item-icon>
            <v-list-item-content class="py-2">
              <v-list-item-title class="text-body-1 black--text">{{ i }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-window-item>
    <v-window-item :value="1" class="pa-5">
      <v-btn class="mb-2 ml-n3" @click="closeDrawer" text color="gray">
        <!-- <v-btn class="mb-2 ml-n3" @click="step = 0" text color="gray"> -->
        <v-icon left>mdi-chevron-left mdi-24px</v-icon>{{ $t('btn.back') }}
      </v-btn>
      <Account />
    </v-window-item>
    <!-- <v-window-item :value="2" class="pa-5">
      <v-btn class="mb-2 ml-n3" @click="step = 0" text color="gray">
        <v-icon left>mdi-chevron-left mdi-24px</v-icon>{{ $t('btn.back') }}
      </v-btn>
      <Security v-if="step == 2" />
    </v-window-item>
    <v-window-item :value="3" class="pa-5">
      <v-btn class="mb-2 ml-n3" @click="step = 0" text color="gray">
        <v-icon left>mdi-chevron-left mdi-24px</v-icon>{{ $t('btn.back') }}
      </v-btn>
      <Notifications />
    </v-window-item>
    <v-window-item :value="4" class="pa-5">
      <v-btn class="mb-2 ml-n3" @click="step = 0" text color="gray">
        <v-icon left>mdi-chevron-left mdi-24px</v-icon>{{ $t('btn.back') }}
      </v-btn>
      <Social />
    </v-window-item>
    <v-window-item :value="5" class="pa-5">
      <v-btn class="mb-2 ml-n3" @click="step = 0" text color="gray">
        <v-icon left>mdi-chevron-left mdi-24px</v-icon>{{ $t('btn.back') }}
      </v-btn>
      <Terms />
    </v-window-item>
    <v-window-item :value="6" class="pa-5">
      <v-btn class="mb-2 ml-n3" @click="step = 0" text color="gray">
        <v-icon left>mdi-chevron-left mdi-24px</v-icon>{{ $t('btn.back') }}
      </v-btn>
      <Kyc v-if="step == 6" />
    </v-window-item> -->
  </v-window>
</template>

<script>
import { eventBus } from '@/main';
import Account from './../tabs/profile/Account.vue';
// import Security from './../tabs/profile/Security.vue';
// import Notifications from './../tabs/profile/Notifications.vue';
// import Social from './../tabs/profile/Social.vue';
// import Terms from './../tabs/profile/Terms.vue';
// import Kyc from './../tabs/profile/Kyc.vue';

export default {
  components: {
    Account,
    // Security,
    // Notifications,
    // Social,
    // Terms,
    // Kyc,
  },
  data() {
    return {
      step: 1,
      icons: ['user', 'lock', 'notifi', 'group', 'terms', 'kyc'],
      profileDrawer: false,
    };
  },
  methods: {
    getIcon(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
    closeDrawer() {
      eventBus.$emit('closeDrawer', {
        profileDrawer: this.profileDrawer,
        drawer: true,
      });
    },
  },
};
</script>

<style lang="scss">
.mobile-menu {
  .v-list-item {
    border-bottom: none !important;
  }
}
</style>
