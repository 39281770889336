import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/portfolio/';

export default {
  state: {
    portfolioSummary: {},
    portfolioPerformance: [],
    portfolioSecurities: [{ security: { id: 'all', exchange_symbol: 'All Stocks' } }],
    portfolioSecuritiesTrans: {},
  },
  actions: {
    getPortfolioSummary(context) {
      return axios.get(`${api}summary`).then((res) => {
        context.commit('GET_PORTFOLIOSUMMARY', res.data);
      });
    },
    getPortfolioPerformance(context, payload) {
      if (payload.security != 'all' || payload.period != '1m') {
        window.history.pushState(
          {},
          '',
          `${router.app._route.path}?period=${payload.period}${payload.security != 'all' ? `&security=${payload.security}` : ''}`
        );
        return axios
          .get(`${api}performance?period=${payload.period}${payload.security != 'all' ? `&security=${payload.security}` : ''}`)
          .then((res) => {
            context.commit('GET_PORTFOLIOPERFORMANCE', res.data);
          });
      } else {
        window.history.pushState({}, '', `${router.app._route.path}`);
        return axios.get(`${api}performance?period=${payload.period}`).then((res) => {
          context.commit('GET_PORTFOLIOPERFORMANCE', res.data);
        });
      }
    },
    getPortfolioSecurities(context) {
      return axios.get(`${api}securities/`).then((res) => {
        context.commit('GET_PORTFOLIOSECURITIES', res.data);
      });
    },
    getPortfolioSecuritiesTrans(context, payload) {
      if (router.app._route.name != 'Dashboard') {
        if (`${router.app._route.path}${payload != 1 ? `?page=${payload || 1}` : ''}` != router.app._route.fullPath) {
          router.push(`${router.app._route.path}${payload != 1 ? `?page=${payload || 1}` : ''}`);
        }
      }
      return axios.get(`${api}securities/transactions?page=${payload - 1 || 0}`).then((res) => {
        context.commit('GET_PORTFOLIOSECURITIESTRANS', res.data);
      });
    },
    setPortfolio: (context) => {
      context.commit('GET_PORTFOLIOSUMMARY', {});
      context.commit('GET_PORTFOLIOPERFORMANCE', []);
      context.commit('GET_PORTFOLIOSECURITIES', []);
      context.commit('GET_PORTFOLIOSECURITIESTRANS', {});
    },
  },
  mutations: {
    GET_PORTFOLIOSUMMARY: (state, payload) => {
      state.portfolioSummary = payload;
      return state.portfolioSummary;
    },
    GET_PORTFOLIOPERFORMANCE: (state, payload) => {
      state.portfolioPerformance = payload;
      return state.portfolioPerformance;
    },
    GET_PORTFOLIOSECURITIES: (state, payload) => {
      state.portfolioSecurities = payload;
      return state.portfolioSecurities;
    },
    GET_PORTFOLIOSECURITIESTRANS: (state, payload) => {
      state.portfolioSecuritiesTrans = payload;
      return state.portfolioSecuritiesTrans;
    },
  },
  getters: {
    portfolioSummary(state) {
      return state.portfolioSummary;
    },
    portfolioPerformance(state) {
      return state.portfolioPerformance;
    },
    portfolioSecurities(state) {
      return state.portfolioSecurities;
    },
    portfolioSecuritiesTrans(state) {
      return state.portfolioSecuritiesTrans;
    },
  },
};
