import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/markets/';
const apiS = '/customer/api/v1/securities/';
const apiP = '/customer/api/v1/users/me/securities/';

export default {
  state: {
    marketsList: [],
    marketsItem: {},
    marketsItemSectors: [],
    securitiesList: [],
    securitiesItem: {},
    securitiesItemPrice: [],
    securitiesFavorit: [],
  },
  actions: {
    getMarketsList(context, payload) {
      if (payload) {
        if (`${router.app._route.path}?src=${payload}` != router.app._route.fullPath) {
          window.history.pushState({}, '', `${router.app._route.path}?src=${payload}`);
        }
        return axios.get(`${api}?src=${payload}`).then((res) => {
          context.commit('GET_MARKETLIST', res.data);
        });
      } else {
        if (router.app._route.path == router.app._route.fullPath) {
          window.history.pushState({}, '', `${router.app._route.path}`);
        }
        return axios.get(`${api}`).then((res) => {
          context.commit('GET_MARKETLIST', res.data);
        });
      }
    },
    setMarketsList: (context, payload) => {
      context.commit('GET_MARKETLIST', payload);
    },
    getMarketsItem(context, payload) {
      return axios.get(`${api}${payload}`).then((res) => {
        context.commit('GET_MARKETITEM', res.data);
      });
    },
    setMarketsItem: (context, payload) => {
      context.commit('GET_MARKETITEM', payload);
    },
    getMarketsItemSectors(context, { id, risk }) {
      return axios.get(`${api}${id}/sectors/${risk ? `?risk_profile=${risk}` : ''}`).then((res) => {
        context.commit('GET_MARKETITEMSECTORS', res.data);
      });
    },
    setMarketsItemSectors: (context) => {
      context.commit('GET_MARKETITEMSECTORS', []);
    },
    //                         SECURITIES
    getSecuritiesList(context, { page, sector, sort, riskProfile, search }) {
      if (sector || sort || riskProfile || !!search) {
        if (
          `${router.app._route.path}${sector.length ? `?sector=${sector}&` : '?'}${sort ? `sort=${sort}&` : ''}${
            riskProfile ? `risk_profile=${riskProfile}&` : ''
          }${search ? `src=${search}&` : ''}page=${page - 1 || 0}` != router.app._route.fullPath
        ) {
          window.history.pushState(
            {},
            '',
            `${router.app._route.path}${sector.length ? `?sector=${sector}&` : '?'}${sort ? `sort=${sort}&` : ''}${
              riskProfile ? `risk_profile=${riskProfile}&` : ''
            }${search ? `src=${search}&` : ''}page=${page - 1 || 0}`
          );
        }
        const sectorValue = [];
        for (var i = 0; i < sector.length; i++) {
          if (i == 0) {
            sectorValue[i] = `?sector=${sector[i]}&`;
          } else if (!sectorValue[i]) {
            sectorValue[i] = `sector=${sector[i]}&`;
          }
        }
        return axios
          .get(
            `${apiS}${sectorValue.join('') || '?'}${sort ? `sort=${sort}&` : ''}${riskProfile ? `risk_profile=${riskProfile}&` : ''}${
              !!search ? `src=${search}&` : ''
            }page=${page - 1 || 0}`
          )
          .then((res) => {
            context.commit('GET_SECURITIESLIST', res.data);
          });
      } else {
        if (router.app._route.path == router.app._route.fullPath) {
          window.history.pushState({}, '', `${router.app._route.path}`);
        }
        return axios.get(`${apiS}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_SECURITIESLIST', res.data);
        });
      }
    },
    setSecuritiesList: (context, payload) => {
      context.commit('GET_SECURITIESLIST', payload);
    },
    getSecuritiesItem(context) {
      return axios.get(`${apiS}${router.app._route.params.stockId}`).then((res) => {
        context.commit('GET_SECURITIESITEM', res.data);
      });
    },
    setSecuritiesItem: (context, payload) => {
      context.commit('GET_SECURITIESITEM', payload);
    },
    getSecuritiesItemPrice(context, payload) {
      return axios.get(`${apiS}${router.app._route.params.stockId}/price?period=${payload}`).then((res) => {
        context.commit('GET_SECURITIESITEMPRICE', res.data);
      });
    },
    setSecuritiesItemPrice: (context, payload) => {
      context.commit('GET_SECURITIESITEMPRICE', payload);
    },
    getSecuritiesListFavorit(context, { sector, sort, search }) {
      if (sector || sort || !!search) {
        if (
          `${router.app._route.path}${sector.length ? `?sector=${sector}` : '?'}${sort ? `&sort=${sort}` : ''}${
            !!search ? `&src=${search}` : ''
          }` != router.app._route.fullPath
        ) {
          window.history.pushState(
            {},
            '',
            `${router.app._route.path}${sector.length ? `?sector=${sector}` : '?'}${sort ? `&sort=${sort}` : ''}${
              !!search ? `&src=${search}` : ''
            }`
          );
        }
        const sectorValue = [];
        for (var i = 0; i < sector.length; i++) {
          if (i == 0) {
            sectorValue[i] = `?sector=${sector[i]}`;
          } else if (!sectorValue[i]) {
            sectorValue[i] = `&sector=${sector[i]}`;
          }
        }
        return axios
          .get(`${apiP}preferred/${sectorValue.join('') || '?'}${sort ? `&sort=${sort}` : ''}${!!search ? `&src=${search}` : ''}`)
          .then((res) => {
            context.commit('GET_SECURITIESFAVORIT', res.data);
          });
      } else {
        if (router.app._route.path == router.app._route.fullPath) {
          window.history.pushState({}, '', `${router.app._route.path}`);
        }
        return axios.get(`${apiP}preferred/`).then((res) => {
          context.commit('GET_SECURITIESFAVORIT', res.data);
        });
      }
    },
    setSecuritiesListFavorit: (context, payload) => {
      context.commit('GET_SECURITIESLIST', payload);
    },
    fevoritSecuritiesItem(context, payload) {
      return axios({
        method: 'post',
        url: `${apiP}preferred/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    updateSecuritiesItem(context, { id, data }) {
      return axios({
        method: 'patch',
        url: `${apiP}preferred/${id}`,
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    deleteSecuritiesItem(context, payload) {
      return axios.delete(`${apiP}preferred/${payload}`);
    },
  },
  mutations: {
    GET_MARKETLIST: (state, payload) => {
      state.marketsList = payload;
      return state.marketsList;
    },
    GET_MARKETITEM: (state, payload) => {
      state.marketsItem = payload;
      return state.marketsItem;
    },
    GET_MARKETITEMSECTORS: (state, payload) => {
      state.marketsItemSectors = payload;
      return state.marketsItemSectors;
    },
    GET_SECURITIESLIST: (state, payload) => {
      state.securitiesList = payload;
      return state.securitiesList;
    },
    GET_SECURITIESITEM: (state, payload) => {
      state.securitiesItem = payload;
      return state.securitiesItem;
    },
    GET_SECURITIESITEMPRICE: (state, payload) => {
      state.securitiesItemPrice = payload;
      return state.securitiesItemPrice;
    },
    GET_SECURITIESFAVORIT: (state, payload) => {
      state.securitiesFavorit = payload;
      return state.securitiesFavorit;
    },
  },
  getters: {
    marketsList(state) {
      return state.marketsList;
    },
    marketsItem(state) {
      return state.marketsItem;
    },
    marketsItemSectors(state) {
      return state.marketsItemSectors;
    },
    securitiesList(state) {
      return state.securitiesList;
    },
    securitiesItem(state) {
      return state.securitiesItem;
    },
    securitiesItemPrice(state) {
      return state.securitiesItemPrice;
    },
    securitiesFavorit(state) {
      return state.securitiesFavorit;
    },
  },
};
