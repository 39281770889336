import '@/plugins/axios';

const api = '/customer/api/v1/users/me';

export default {
  state: {
    profile: {},
  },
  actions: {
    supportFunctionProfile(context, payload) {
      const data = payload;
      data.general_monthly_income ? (data.gmi = data.general_monthly_income / 100) : '';
      data.additional_monthly_income ? (data.ami = data.additional_monthly_income / 100) : '';
      data.general_monthly_expenses ? (data.gme = data.general_monthly_expenses / 100) : '';
      context.commit('GET_PROFILE', data);
    },
    getProfile(context) {
      return axios.get(`${api}`).then((res) => {
        context.dispatch('supportFunctionProfile', res.data);
      });
    },
    setProfile: (context, payload) => {
      context.commit('GET_PROFILE', payload);
    },
    updateProfile(context, payload) {
      return axios({
        method: 'patch',
        url: api,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.dispatch('supportFunctionProfile', res.data);
      });
    },
    updateProfileKyc(context, payload) {
      return axios({
        method: 'post',
        url: `${api}/kyc`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.dispatch('supportFunctionProfile', res.data);
      });
    },
    updatePhoneProfile(context, payload) {
      return axios.post(`${api}/phone`, payload);
    },
    updatePhoneOtpProfile(context, payload) {
      return axios.post(`${api}/phone/otp`, payload).then((res) => {
        context.dispatch('supportFunctionProfile', res.data);
      });
    },
  },
  mutations: {
    GET_PROFILE: (state, payload) => {
      state.profile = payload;
      return state.profile;
    },
  },
  getters: {
    profile(state) {
      return state.profile;
    },
  },
};
