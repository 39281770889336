<template>
  <v-card class="d-flex settings" rounded="lg" flat width="100%" min-height="700">
    <v-card color="accent" tile flat max-width="370" width="100%" class="py-9 pl-9 pr-5 black--text">
      <div class="f30 mb-12">{{ $t('setting.title') }}</div>
      <v-tabs vertical background-color="transparent" v-model="tab">
        <v-tabs-slider color="transparent"></v-tabs-slider>
        <v-tab v-for="(i, j) in $t(`setting.tabs`)" :key="i" class="justify-start mb-4 text-body-1" active-class="primary white--text">
          <v-img height="24" max-width="24" :src="getImage(icons[j])" class="mr-4"></v-img>
          {{ i }}
        </v-tab>
      </v-tabs>
    </v-card>
    <v-card tile flat max-width="830" width="100%" class="pt-9 pb-7 px-10 black--text">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Account />
        </v-tab-item>
        <!-- <v-tab-item>
          <Security v-if="tab == 1" />
        </v-tab-item>
        <v-tab-item>
          <Notifications />
        </v-tab-item>
        <v-tab-item>
          <Social />
        </v-tab-item>
        <v-tab-item>
          <Terms />
        </v-tab-item>
        <v-tab-item>
          <Kyc v-if="tab == 5" />
        </v-tab-item> -->
      </v-tabs-items>
    </v-card>
  </v-card>
</template>

<script>
import Account from './tabs/profile/Account.vue';
// import Security from './tabs/profile/Security.vue';
// import Notifications from './tabs/profile/Notifications.vue';
// import Social from './tabs/profile/Social.vue';
// import Terms from './tabs/profile/Terms.vue';
// import Kyc from './tabs/profile/Kyc.vue';

export default {
  components: {
    Account,
    // Security,
    // Notifications,
    // Social,
    // Terms,
    // Kyc,
  },
  data() {
    return {
      tab: 0,
      icons: ['user', 'lock', 'notifi', 'group', 'terms', 'kyc'],
    };
  },
  methods: {
    getImage(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
  },
  destroyed() {
    this.tab = 0;
  },
};
</script>

<style lang="scss">
.settings {
  position: relative;
}
#app .v-dialog--active {
  .v-tab {
    text-transform: none;
    border-radius: 6px;
    height: 70px;
    color: #909ab0;
  }
  .v-tab--active .v-image {
    filter: brightness(200%);
  }
}
</style>
