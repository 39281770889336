import '@/plugins/axios';

const api = '/api/v1/';

export default {
  state: {
    langValue: localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')) : { name: 'Arabic', key: 'ar' },
    countries: [],
    city: [],
    ageGroup: [],
    incomeSource: [],
    investmentInterest: [],
    balance: {},
    transactionsList: {},
    paymentCardList: [],
    paymentInfo: {},
    monthPay: {},
    monthPayNumber: {},
  },
  actions: {
    setLang: (context, payload) => {
      context.commit('GET_LANG', payload);
    },
    getCoutries(context) {
      return axios.get(api + 'countries/').then((res) => {
        context.commit('GET_COUNTRIES', res.data);
      });
    },
    setCoutries: (context, payload) => {
      context.commit('GET_COUNTRIES', payload);
    },
    getCity(context, payload) {
      return axios.get(`${api}cities/?src=${payload}`).then((res) => {
        context.commit('GET_CITY', res.data);
      });
    },
    setCity: (context, payload) => {
      context.commit('GET_CITY', payload);
    },
    getAgeGroup(context) {
      return axios.get(`${api}age-groups/`).then((res) => {
        context.commit('GET_AGEGROUP', res.data);
      });
    },
    getIncomeSource(context) {
      return axios.get(`${api}income-source/`).then((res) => {
        context.commit('GET_INCOMESOURCE', res.data);
      });
    },
    getInvestmentInterest(context) {
      return axios.get(`${api}investment-interest/`).then((res) => {
        context.commit('GET_INVESTMENTINTEREST', res.data);
      });
    },
    getBalance(context) {
      return axios.get(`/customer${api}users/me/balance`).then((res) => {
        context.commit('GET_BALANCE', res.data);
      });
    },
    updateBalance(context, payload) {
      return axios.post(`/customer${api}users/me/balance/top-up`, payload).then((res) => {
        context.commit('GET_BALANCE', res.data);
      });
    },
    getTransactions(context) {
      return axios.get(`/customer${api}users/me/transactions/`).then((res) => {
        context.commit('GET_TRANSACTION', res.data);
      });
    },
    setTransactions(context, payload) {
      context.commit('GET_TRANSACTION', payload);
    },
    getPaymentCardList(context) {
      return axios.get(`/customer${api}payment-cards/`).then((res) => {
        context.commit('GET_PAYMENTCARDLIST', res.data);
      });
    },
    getPaymentInfo(context, payload) {
      return axios.get(`/customer${api}payments/${payload}`).then((res) => {
        context.commit('GET_PAYMENTINFO', res.data);
      });
    },
    setPaymentCard(context) {
      context.commit('GET_PAYMENTCARDLIST', []);
      context.commit('GET_PAYMENTINFO', {});
    },
    getMonthPay(context, payload) {
      return axios.get(`/customer${api}monthly-payment?gmi=${payload.gmi}&gme=${payload.gme}&ami=${payload.ami}`).then((res) => {
        context.commit('GET_MONTHPAY', res.data);
      });
    },
    getMonthPayNumber(context) {
      return axios.get(`/customer${api}users/me/monthly-payment`).then((res) => {
        context.commit('GET_MONTHPAYNUMBER', res.data);
      });
    },
    setMonthPay(context) {
      context.commit('GET_MONTHPAY', {});
    },
  },
  mutations: {
    GET_LANG: (state, payload) => {
      state.langValue = payload;
      return state.langValue;
    },
    GET_COUNTRIES: (state, payload) => {
      state.countries = payload;
      return state.countries;
    },
    GET_CITY: (state, payload) => {
      state.city = payload;
      return state.city;
    },
    GET_AGEGROUP: (state, payload) => {
      state.ageGroup = payload;
      return state.ageGroup;
    },
    GET_INCOMESOURCE: (state, payload) => {
      state.incomeSource = payload;
      return state.incomeSource;
    },
    GET_INVESTMENTINTEREST: (state, payload) => {
      state.investmentInterest = payload;
      return state.investmentInterest;
    },
    GET_BALANCE: (state, payload) => {
      state.balance = payload;
      return state.balance;
    },
    GET_TRANSACTION: (state, payload) => {
      state.transactionsList = payload;
      return state.transactionsList;
    },
    GET_PAYMENTCARDLIST: (state, payload) => {
      state.paymentCardList = payload;
      return state.transactionsList;
    },
    GET_PAYMENTINFO: (state, payload) => {
      state.paymentInfo = payload;
      return state.paymentInfo;
    },
    GET_MONTHPAY: (state, payload) => {
      state.monthPay = payload;
      return state.monthPay;
    },
    GET_MONTHPAYNUMBER: (state, payload) => {
      state.monthPayNumber = payload;
      return state.monthPayNumber;
    },
  },
  getters: {
    langValue(state) {
      return state.langValue;
    },
    countries(state) {
      return state.countries;
    },
    city(state) {
      return state.city;
    },
    ageGroup(state) {
      return state.ageGroup;
    },
    incomeSource(state) {
      return state.incomeSource;
    },
    investmentInterest(state) {
      return state.investmentInterest;
    },
    balance(state) {
      return state.balance;
    },
    transactionsList(state) {
      return state.transactionsList;
    },
    paymentCardList(state) {
      return state.paymentCardList;
    },
    paymentInfo(state) {
      return state.paymentInfo;
    },
    monthPay(state) {
      return state.monthPay;
    },
    monthPayNumber(state) {
      return state.monthPayNumber;
    },
  },
};
