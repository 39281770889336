<template>
  <div>
    <v-navigation-drawer color="#ffffff" fixed overlay-opacity temporary v-model="drawer" width="375" class="mobile-menu">
      <!-- <div class="gray--text ms-6 my-5">{{ $t('menu.investing') }}</div> -->
      <MenuList class="px-4 py-0 mt-2" />
      <!-- <MenuListMore class="pa-0" /> -->
      <v-divider class="mt-5"></v-divider>
      <div class="gray--text ms-6 mb-5 mt-7">{{ $t('menu.more') }}</div>
      <v-list class="px-4 py-0">
        <v-list-item link @click="profile" class="ps-7">
          <v-list-item-icon>
            <v-img width="24" height="24" src="@/assets/icon/setting.svg"></v-img>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('menu.setting') }}</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="logout" class="ps-7">
          <v-list-item-icon>
            <v-img width="24" height="24" src="@/assets/icon/logout.svg"></v-img>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('menu.singOut') }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-spacer></v-spacer>
      <v-menu offset-y nudge-bottom="-170" nudge-right="82" min-width="210">
        <template v-slot:activator="{ on, attrs, value }">
          <div v-bind="attrs" v-on="on" class="d-flex justify-center my-7">
            <v-card flat class="primary white--text d-flex align-center justify-center" height="44" width="210">
              <img src="@/assets/icon/global.svg" alt="earth" class="pe-5 white-filter" /> {{ select.name }}
              <v-card flat class="primary d-flex align-center justify-center ms-5" height="44" width="42">
                <img src="@/assets/icon/arrow.svg" alt="arrow" :class="value ? '' : 'rotate'" />
              </v-card>
            </v-card>
          </div>
        </template>
        <v-list>
          <v-list-item v-for="item in lang" :key="item.key" @click="changeLang(item)" style="border: none">
            <img :src="flagImg(item.key == 'ar' ? 'ae' : 'gb')" alt="flag" />
            <span class="px-5">{{ item.name }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-navigation-drawer>
    <v-navigation-drawer color="#ffffff" fixed overlay-opacity temporary v-model="profileDrawer" width="100%" class="mobile-menu">
      <MenuProfile v-if="profileDrawer" />
    </v-navigation-drawer>
    <v-app-bar elevation="1" :height="$vuetify.breakpoint.xs ? 78 : 84" :color="$vuetify.breakpoint.xs ? 'white' : 'accent'" class="header">
      <div class="wrap d-flex flex-wrap align-center">
        <div class="logo d-lg-none">
          <v-img max-height="38" max-width="108" contain src="@/assets/img/logo.svg" alt="logo"></v-img>
        </div>
        <!-- <v-img max-height="38" max-width="108" contain src="@/assets/img/logo.svg" alt="logo"></v-img> -->
        <v-spacer></v-spacer>
        <!-- <v-btn min-width="44" height="44" plain icon>
          <v-badge
            color="error"
            class="link"
            :content="5"
            :value="5"
            bottom
            offset-x="15"
            offset-y="30"
          >
            <v-img height="24" max-width="24" contain src="@/assets/icon/bell.svg"></v-img>
          </v-badge>
        </v-btn> -->
        <v-menu offset-y nudge-left="360" nudge-bottom="22">
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn @click.stop="getNotifi" plain icon v-bind="attrs" v-on="on">
              <v-badge
                color="primary"
                class="link"
                :content="unread.unread_count"
                :value="unread.unread_count"
                bottom
                offset-x="15"
                offset-y="30"
              >
                <v-img
                  :class="value ? 'primary-filter' : ''"
                  :height="$vuetify.breakpoint.xs ? 32 : 24"
                  :min-width="$vuetify.breakpoint.xs ? 32 : 24"
                  contain
                  src="@/assets/icon/bell.svg"
                ></v-img>
              </v-badge>
            </v-btn>
          </template>
          <v-card class="py-3 view-without-scroll" max-width="440" width="100%" max-height="450">
            <v-card flat tile width="400" class="d-flex align-center flex-wrap px-6">
              <div class="f18 font-weight-bold">{{ $t('notifications.title') }}</div>
              <v-spacer></v-spacer>
              <v-btn v-if="notifiList.results.find((item) => item.seen == false)" @click="readAllNotifi" text>
                <span class="primary--text">{{ $t('btn.readAll') }}</span>
              </v-btn>
            </v-card>
            <div v-for="(i, j) in notifiList.results" :key="i.id" class="text-body-2 link" @click="notifi(i)">
              <v-divider class="grey lighten-2 mb-4" :class="j ? 'mt-4' : ''"></v-divider>
              <div class="px-6">{{ fixPoint(i) }}</div>
              <div class="d-flex align-center px-6 mt-5">
                <div class="gray--text">{{ new Date(i.date).toLocaleString() }}</div>
                <v-spacer></v-spacer>
                <div v-if="!i.seen" class="primary--text text-h5">•</div>
              </div>
            </div>
          </v-card>
        </v-menu>
        <v-menu offset-y nudge-bottom="22">
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn class="d-sm-block d-none" :class="`${value ? 'primary' : 'gray'}--text`" plain v-bind="attrs" v-on="on" height="44">
              {{ select.name }}
              <v-icon :color="value ? 'primary' : 'gray'">
                {{ value ? 'mdi-chevron-down' : $vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in lang" :key="item.key" @click="changeLang(item)">
              <img :src="flagImg(item.key == 'ar' ? 'ae' : 'gb')" alt="flag" />
              <span class="px-5">{{ item.name }}</span>
              <v-icon>{{ $vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="text-center ms-5 d-lg-block d-none">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-list color="accent" class="pa-0">
                  <v-list-item>
                    <v-list-item-avatar height="50" width="50">
                      <v-img v-if="data.picture != null" :src="data.picture"></v-img>
                      <div v-else>
                        <v-icon color="gray">mdi-account mdi-48px</v-icon>
                      </div>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="black--text font-weight-bold">
                        {{ data.first_name || '' }} {{ data.last_name || '' }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-avatar min-width="36" width="36" height="36">
                  <v-img v-if="data.picture != null" :src="data.picture"></v-img>
                  <div v-else>
                    <v-icon color="black">mdi-account mdi-36px</v-icon>
                  </div>
                </v-list-item-avatar>
                <v-list-item-content v-if="data.email">
                  <v-list-item-title class="black--text">{{ data.email }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item link @click="profile">
                <v-list-item-icon>
                  <v-img width="24" height="24" src="@/assets/icon/setting.svg"></v-img>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('menu.setting') }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="logout">
                <v-list-item-icon>
                  <v-img width="24" height="24" src="@/assets/icon/logout.svg"></v-img>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('menu.singOut') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-app-bar-nav-icon @click="drawer = !drawer" class="d-lg-none">
          <v-img max-width="24" height="16" contain v-if="!drawer" src="@/assets/icon/menu.svg"></v-img>
          <v-icon color="gray" v-else>mdi-close</v-icon>
        </v-app-bar-nav-icon>
      </div>
    </v-app-bar>
    <v-dialog v-model="modal" max-width="1200" width="100%">
      <Settings v-if="modal" />
    </v-dialog>
  </div>
</template>

<script>
import MenuList from './MenuList';
// import MenuListMore from './MenuListMore';
import MenuProfile from './mobileProfile/MenuProfile';
import Settings from './Settings';
import { eventBus } from '@/main';

export default {
  components: {
    MenuList,
    // MenuListMore,
    Settings,
    MenuProfile,
  },
  data() {
    return {
      modal: false,
      profileDrawer: false,
      drawer: false,
      lang: [
        { name: 'English', key: 'en' },
        { name: 'Arabic', key: 'ar' },
      ],
    };
  },
  created() {
    eventBus.$on('closeDrawer', (data) => {
      this.profileDrawer = data.profileDrawer;
      this.drawer = data.drawer;
    });
    eventBus.$on('closeSetting', (data) => {
      this.modal = data.modal;
    });
  },
  mounted() {
    this.axios.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang')
      ? JSON.parse(localStorage.getItem('lang')).key
      : 'ar';
    this.$i18n.locale = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')).key : 'ar';
    this.$vuetify.rtl = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')).key == 'ar' : true;
    this.$store.dispatch('getProfile');
    const _onInit = (auth2) => {
      console.log('init OK', auth2);
    };
    const _onError = (err) => {
      console.log('error', err);
    };
    window.gapi.load('auth2', () => {
      window.gapi.auth2
        .init({
          client_id: process.env.VUE_APP_GOOGLE,
          scope: 'email profile openid',
          fetch_basic_profile: false,
        })
        .then(_onInit, _onError);
    });
    this.getNotifi();
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout');
    },
    profile() {
      if (window.innerWidth < 800) {
        this.profileDrawer = true;
        this.drawer = false;
      } else {
        this.modal = true;
      }
    },
    flagImg(code) {
      return require(`@/assets/icon/flags/img_flag_${code}.png`);
    },
    changeLang(item) {
      this.axios.defaults.headers.common['Accept-Language'] = item.key;
      localStorage.setItem('lang', JSON.stringify(item));
      this.$store.dispatch('setLang', item);
    },
    getNotifi() {
      this.$store.dispatch('getNotificationList', 1);
      this.$store.dispatch('getNotificationUnread');
    },
    readAllNotifi() {
      this.$store.dispatch('postNotificationSeenAll');
    },
    notifi(i) {
      if (i.type == 'payment_reminder_v1') {
        this.$route.path != '/wallet/overview' ? this.$router.push('/wallet/overview') : '';
      }
      !i.seen ? this.$store.dispatch('postNotificationSeen', i.id) : '';
    },
    fixPoint(j) {
      if (j.type == 'payment_reminder_v1') {
        return this.$t('notifications.title');
      } else {
        const a = j.type.split(' ');
        return a.join(' ');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.profile;
    },
    select() {
      return this.$store.getters.langValue;
    },
    unread() {
      return this.$store.getters.notificationsUnread;
    },
    notifiList() {
      return this.$store.getters.notificationsList;
    },
  },
};
</script>

<style>
.header .v-toolbar__content {
  width: calc(100% - 256px);
  margin-left: auto;
}
.header .v-avatar {
  border-radius: 10px;
}
.v-menu__content .v-avatar {
  border-radius: 4px;
}
.mobile-menu {
  top: 84px !important;
  height: calc(100% - 84px) !important;
}
.mobile-menu .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}
.mobile-menu .icon {
  filter: brightness(1%);
}
.v-application--is-rtl .header .v-toolbar__content {
  margin-left: initial;
  margin-right: auto;
}
@media screen and (max-width: 1263px) {
  .header .v-toolbar__content {
    width: initial;
    margin-left: initial;
  }
}
@media screen and (max-width: 599px) {
  .mobile-menu {
    top: 78px !important;
    height: calc(100% - 78px) !important;
  }
}
</style>
