import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/notifications/';

export default {
  state: {
    notificationsUnread: {},
    notificationsList: { results: [] },
    notificationsItem: [],
  },
  actions: {
    getNotificationUnread(context) {
      return axios.get(`${api}unread`).then((res) => {
        context.commit('GET_NOTIFICATIONUNREAD', res.data);
      });
    },
    getNotificationList(context, payload) {
      if (router.app._route.name != 'Dashboard') {
        if (`${router.app._route.path}${payload != 1 ? `?page=${payload || 1}` : ''}` != router.app._route.fullPath) {
          router.push(`${router.app._route.path}${payload != 1 ? `?page=${payload || 1}` : ''}`);
        }
      }
      return axios.get(`${api}?page=${payload - 1 || 0}`).then((res) => {
        context.commit('GET_NOTIFICATIONLIST', res.data);
      });
    },
    postNotificationSeenAll(context) {
      return axios.post(`${api}seen-all`).then(() => {
        context.dispatch('getNotificationList', 1);
      });
    },
    getNotificationItem(context) {
      return axios.get(`${api}${router.app._route.params.stockId}`).then((res) => {
        context.commit('GET_NOTIFICATIONITEM', res.data);
      });
    },
    postNotificationSeen(context, payload) {
      return axios.post(`${api}${payload}/seen`).then((res) => {
        context.commit('GET_NOTIFICATIONITEM', res.data);
      });
    },
    setNotifications: (context) => {
      context.commit('GET_NOTIFICATIONUNREAD', {});
      context.commit('GET_NOTIFICATIONLIST', { results: [] });
      context.commit('GET_NOTIFICATIONITEM', []);
    },
  },
  mutations: {
    GET_NOTIFICATIONUNREAD: (state, payload) => {
      state.notificationsUnread = payload;
      return state.notificationsUnread;
    },
    GET_NOTIFICATIONLIST: (state, payload) => {
      state.notificationsList = payload;
      return state.notificationsList;
    },
    GET_NOTIFICATIONITEM: (state, payload) => {
      state.notificationsItem = payload;
      return state.notificationsItem;
    },
  },
  getters: {
    notificationsUnread(state) {
      return state.notificationsUnread;
    },
    notificationsList(state) {
      return state.notificationsList;
    },
    notificationsItem(state) {
      return state.notificationsItem;
    },
  },
};
