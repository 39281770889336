var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{staticClass:"mobile-menu",attrs:{"color":"#ffffff","fixed":"","overlay-opacity":"","temporary":"","width":"375"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('MenuList',{staticClass:"px-4 py-0 mt-2"}),_c('v-divider',{staticClass:"mt-5"}),_c('div',{staticClass:"gray--text ms-6 mb-5 mt-7"},[_vm._v(_vm._s(_vm.$t('menu.more')))]),_c('v-list',{staticClass:"px-4 py-0"},[_c('v-list-item',{staticClass:"ps-7",attrs:{"link":""},on:{"click":_vm.profile}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"width":"24","height":"24","src":require("@/assets/icon/setting.svg")}})],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menu.setting')))])],1),_c('v-list-item',{staticClass:"ps-7",attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"width":"24","height":"24","src":require("@/assets/icon/logout.svg")}})],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menu.singOut')))])],1)],1),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"-170","nudge-right":"82","min-width":"210"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var value = ref.value;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex justify-center my-7"},'div',attrs,false),on),[_c('v-card',{staticClass:"primary white--text d-flex align-center justify-center",attrs:{"flat":"","height":"44","width":"210"}},[_c('img',{staticClass:"pe-5 white-filter",attrs:{"src":require("@/assets/icon/global.svg"),"alt":"earth"}}),_vm._v(" "+_vm._s(_vm.select.name)+" "),_c('v-card',{staticClass:"primary d-flex align-center justify-center ms-5",attrs:{"flat":"","height":"44","width":"42"}},[_c('img',{class:value ? '' : 'rotate',attrs:{"src":require("@/assets/icon/arrow.svg"),"alt":"arrow"}})])],1)],1)]}}])},[_c('v-list',_vm._l((_vm.lang),function(item){return _c('v-list-item',{key:item.key,staticStyle:{"border":"none"},on:{"click":function($event){return _vm.changeLang(item)}}},[_c('img',{attrs:{"src":_vm.flagImg(item.key == 'ar' ? 'ae' : 'gb'),"alt":"flag"}}),_c('span',{staticClass:"px-5"},[_vm._v(_vm._s(item.name))])])}),1)],1)],1),_c('v-navigation-drawer',{staticClass:"mobile-menu",attrs:{"color":"#ffffff","fixed":"","overlay-opacity":"","temporary":"","width":"100%"},model:{value:(_vm.profileDrawer),callback:function ($$v) {_vm.profileDrawer=$$v},expression:"profileDrawer"}},[(_vm.profileDrawer)?_c('MenuProfile'):_vm._e()],1),_c('v-app-bar',{staticClass:"header",attrs:{"elevation":"1","height":_vm.$vuetify.breakpoint.xs ? 78 : 84,"color":_vm.$vuetify.breakpoint.xs ? 'white' : 'accent'}},[_c('div',{staticClass:"wrap d-flex flex-wrap align-center"},[_c('div',{staticClass:"logo d-lg-none"},[_c('v-img',{attrs:{"max-height":"38","max-width":"108","contain":"","src":require("@/assets/img/logo.svg"),"alt":"logo"}})],1),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","nudge-left":"360","nudge-bottom":"22"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var value = ref.value;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.getNotifi.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-badge',{staticClass:"link",attrs:{"color":"primary","content":_vm.unread.unread_count,"value":_vm.unread.unread_count,"bottom":"","offset-x":"15","offset-y":"30"}},[_c('v-img',{class:value ? 'primary-filter' : '',attrs:{"height":_vm.$vuetify.breakpoint.xs ? 32 : 24,"min-width":_vm.$vuetify.breakpoint.xs ? 32 : 24,"contain":"","src":require("@/assets/icon/bell.svg")}})],1)],1)]}}])},[_c('v-card',{staticClass:"py-3 view-without-scroll",attrs:{"max-width":"440","width":"100%","max-height":"450"}},[_c('v-card',{staticClass:"d-flex align-center flex-wrap px-6",attrs:{"flat":"","tile":"","width":"400"}},[_c('div',{staticClass:"f18 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('notifications.title')))]),_c('v-spacer'),(_vm.notifiList.results.find(function (item) { return item.seen == false; }))?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.readAllNotifi}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$t('btn.readAll')))])]):_vm._e()],1),_vm._l((_vm.notifiList.results),function(i,j){return _c('div',{key:i.id,staticClass:"text-body-2 link",on:{"click":function($event){return _vm.notifi(i)}}},[_c('v-divider',{staticClass:"grey lighten-2 mb-4",class:j ? 'mt-4' : ''}),_c('div',{staticClass:"px-6"},[_vm._v(_vm._s(_vm.fixPoint(i)))]),_c('div',{staticClass:"d-flex align-center px-6 mt-5"},[_c('div',{staticClass:"gray--text"},[_vm._v(_vm._s(new Date(i.date).toLocaleString()))]),_c('v-spacer'),(!i.seen)?_c('div',{staticClass:"primary--text text-h5"},[_vm._v("•")]):_vm._e()],1)],1)})],2)],1),_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"22"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var value = ref.value;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-sm-block d-none",class:((value ? 'primary' : 'gray') + "--text"),attrs:{"plain":"","height":"44"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.select.name)+" "),_c('v-icon',{attrs:{"color":value ? 'primary' : 'gray'}},[_vm._v(" "+_vm._s(value ? 'mdi-chevron-down' : _vm.$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right')+" ")])],1)]}}])},[_c('v-list',_vm._l((_vm.lang),function(item){return _c('v-list-item',{key:item.key,on:{"click":function($event){return _vm.changeLang(item)}}},[_c('img',{attrs:{"src":_vm.flagImg(item.key == 'ar' ? 'ae' : 'gb'),"alt":"flag"}}),_c('span',{staticClass:"px-5"},[_vm._v(_vm._s(item.name))]),_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right'))])],1)}),1)],1),_c('div',{staticClass:"text-center ms-5 d-lg-block d-none"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-list',{staticClass:"pa-0",attrs:{"color":"accent"}},[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"height":"50","width":"50"}},[(_vm.data.picture != null)?_c('v-img',{attrs:{"src":_vm.data.picture}}):_c('div',[_c('v-icon',{attrs:{"color":"gray"}},[_vm._v("mdi-account mdi-48px")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"black--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.data.first_name || '')+" "+_vm._s(_vm.data.last_name || '')+" ")])],1)],1)],1)],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"min-width":"36","width":"36","height":"36"}},[(_vm.data.picture != null)?_c('v-img',{attrs:{"src":_vm.data.picture}}):_c('div',[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-account mdi-36px")])],1)],1),(_vm.data.email)?_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.data.email))])],1):_vm._e()],1),_c('v-divider'),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.profile}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"width":"24","height":"24","src":require("@/assets/icon/setting.svg")}})],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menu.setting')))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"width":"24","height":"24","src":require("@/assets/icon/logout.svg")}})],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('menu.singOut')))])],1)],1)],1)],1),_c('v-app-bar-nav-icon',{staticClass:"d-lg-none",on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[(!_vm.drawer)?_c('v-img',{attrs:{"max-width":"24","height":"16","contain":"","src":require("@/assets/icon/menu.svg")}}):_c('v-icon',{attrs:{"color":"gray"}},[_vm._v("mdi-close")])],1)],1)]),_c('v-dialog',{attrs:{"max-width":"1200","width":"100%"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(_vm.modal)?_c('Settings'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }