<template>
  <v-card min-height="630" flat tile class="d-flex flex-column justify-space-between">
    <div>
      <div class="f30 mb-4">{{ $t('setting.accountTitle') }}</div>
      <v-row class="mb-12">
        <v-col cols="12" lg="7" class="gray--text">{{ $t('setting.accountDesc') }}</v-col>
      </v-row>
      <div class="d-flex flex-md-nowrap flex-wrap">
        <div class="width100 me-md-3">
          <div class="font-weight-medium">{{ $t('input.firstNameLabel') }}</div>
          <v-text-field
            class="field56"
            v-model="data.first_name"
            :error-messages="firstNameErrors"
            :placeholder="$t('input.namePla')"
            outlined
            required
            color="primary"
            height="56"
          >
            <template v-slot:prepend-inner>
              <img width="24" height="24" src="@/assets/icon/user.svg" />
            </template>
          </v-text-field>
        </div>
        <div class="width100 ms-md-3">
          <div class="font-weight-medium">{{ $t('input.lastNameLabel') }}</div>
          <v-text-field
            class="field56"
            v-model="data.last_name"
            :error-messages="lastNameErrors"
            :placeholder="$t('input.namePla')"
            outlined
            required
            color="primary"
            height="56"
          >
            <template v-slot:prepend-inner>
              <img width="24" height="24" src="@/assets/icon/user.svg" />
            </template>
          </v-text-field>
        </div>
      </div>
      <div class="font-weight-medium">{{ $t('input.emailLabel') }}</div>
      <v-text-field
        class="field56"
        v-model="data.email"
        :error-messages="emailErrors"
        :placeholder="$t('input.emailPla')"
        outlined
        required
        color="primary"
        height="56"
      >
        <template v-slot:prepend-inner>
          <img width="24" height="24" src="@/assets/icon/mail.svg" />
        </template>
      </v-text-field>
      <div class="font-weight-medium">{{ $t('input.birthdayLabel') }}</div>
      <v-menu v-model="modal" :close-on-content-click="false" fixed transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="field56"
            v-model="data.birthday"
            :error-messages="dateErrors"
            :placeholder="$t('input.birthdayPla')"
            outlined
            readonly
            required
            color="primary"
            height="56"
            v-bind="attrs"
            v-on="on"
          >
            <template v-slot:append>
              <img width="24" height="24" src="@/assets/icon/calendar.svg" />
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          :max="new Date(time).toISOString().substr(0, 10)"
          :show-current="data.birthday || new Date(time).toISOString().substr(0, 10)"
          color="primary"
          v-model="data.birthday"
          @input="modal = false"
        ></v-date-picker>
      </v-menu>
    </div>
    <div class="text-sm-right text-center mt-5">
      <v-btn
        depressed
        @click="action"
        height="64"
        :max-width="$vuetify.breakpoint.xs ? 344 : ''"
        :width="$vuetify.breakpoint.xs ? '100%' : 248"
        class="primary"
      >
        {{ $t('btn.save') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { eventBus } from '@/main';

export default {
  data() {
    return {
      modal: false,
      error: [],
    };
  },
  methods: {
    async action() {
      this.error = [];
      const formData = new FormData();
      formData.append('first_name', this.data.first_name);
      formData.append('last_name', this.data.last_name);
      formData.append('birthday', this.data.birthday);
      formData.append('email', this.data.email);
      await this.$store
        .dispatch('updateProfile', formData)
        .then(() => {
          eventBus.$emit('closeSetting', {
            modal: false,
          });
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.profile;
    },
    time() {
      return `${new Date().getFullYear() - 18}-${new Date().getMonth() + 1}-${new Date().getDate() + 1}`;
    },
    firstNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'first_name__required') && errors.push(this.$t('inputError.firstNameRequired'));
      this.error.find((item) => item == 'first_name__invalid') && errors.push(this.$t('inputError.firstNameInvalid'));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'last_name__required') && errors.push(this.$t('inputError.lastNameRequired'));
      this.error.find((item) => item == 'last_name__invalid') && errors.push(this.$t('inputError.lastNameInvalid'));
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push(this.$t('inputError.emailRequired'));
      this.error.find((item) => item == 'email__invalid') && errors.push(this.$t('inputError.emailInvalid'));
      this.error.find((item) => item == 'email__exists') && errors.push(this.$t('inputError.emailExists'));
      this.error.find((item) => item == 'email__update_too_often') && errors.push(this.$t('inputError.emailTooOften'));
      return errors;
    },
    dateErrors() {
      const errors = [];
      this.error.find((item) => item == 'birthday__required') && errors.push(this.$t('inputError.birthdayRequired'));
      this.error.find((item) => item == 'birthday__invalid') && errors.push(this.$t('inputError.birthdayInvalid'));
      return errors;
    },
  },
};
</script>
