<template>
  <div class="empty-layout">
    <div class="d-flex mt-sm-6 mx-sm-12">
      <v-spacer></v-spacer>
      <v-menu
        offset-y
        :nudge-bottom="$vuetify.breakpoint.xs ? '-170' : '12'"
        v-if="$vuetify.breakpoint.xs ? $route.name == 'Login' || $route.name == 'Sign-up' : true"
      >
        <template v-slot:activator="{ on, attrs, value }">
          <div v-bind="attrs" v-on="on" class="lang-btn">
            <v-card flat class="primary white--text d-flex align-center justify-center" height="44" width="210">
              <img src="@/assets/icon/global.svg" alt="earth" class="pe-5 white-filter" /> {{ select.name }}
              <v-card flat class="primary d-flex align-center justify-center ms-5" height="44" width="44">
                <img src="@/assets/icon/arrow.svg" alt="arrow" :class="value ? '' : 'rotate'" />
              </v-card>
            </v-card>
          </div>
        </template>
        <v-list>
          <v-list-item v-for="item in lang" :key="item.key" @click="changeLang(item)" style="border: none">
            <img :src="flagImg(item.key == 'ar' ? 'ae' : 'gb')" alt="flag" />
            <span class="px-5">{{ item.name }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="view" :key="key">
      <v-fab-transition>
        <router-view class="content-view" />
      </v-fab-transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      key: 1,
      lang: [
        { name: 'English', key: 'en' },
        { name: 'Arabic', key: 'ar' },
      ],
    };
  },
  mounted() {
    this.axios.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang')
      ? JSON.parse(localStorage.getItem('lang')).key
      : 'ar';
    this.$i18n.locale = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')).key : 'ar';
    this.$vuetify.rtl = localStorage.getItem('lang') ? JSON.parse(localStorage.getItem('lang')).key == 'ar' : true;
  },
  methods: {
    flagImg(code) {
      return require(`@/assets/icon/flags/img_flag_${code}.png`);
    },
    changeLang(item) {
      this.axios.defaults.headers.common['Accept-Language'] = item.key;
      this.$vuetify.rtl = item.key == 'ar' ? true : false;
      this.$i18n.locale = item.key;
      localStorage.setItem('lang', JSON.stringify(item));
      this.$store.dispatch('setLang', item);
    },
  },
  watch: {
    langKey() {
      this.key = ++this.key;
    },
  },
  computed: {
    select() {
      return this.$store.getters.langValue;
    },
    langKey() {
      return this.$store.getters.langValue.key;
    },
  },
};
</script>

<style lang="scss">
.empty-layout {
  .view {
    width: 100%;
    min-height: calc(100vh - 120px);
    // min-height: 100vh;
    display: flex;
    align-items: center;
  }
  .content-view {
    margin: 10px auto;
  }
  @media screen and (max-width: 1024px) {
    .content-view {
      margin: 10px 20px;
    }
  }
  @media screen and (max-width: 599px) {
    .view {
      width: 100%;
      min-height: 100vh;
      // min-height: 100vh;
      display: flex;
      align-items: center;
    }
    .content-view {
      margin: 0;
    }
    .lang-btn {
      z-index: 2;
      display: flex;
      position: absolute;
      justify-content: center;
      bottom: 40px;
      right: 0;
      left: 0;
    }
  }
}
</style>
