<template>
  <div class="main-layout" :key="key">
    <Header />
    <div class="d-flex">
      <Menu class="d-none d-lg-block" />
      <router-view class="content-view" />
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Header from '@/components/Header';
import Menu from '@/components/Menu';
// import Footer from '@/components/Footer';

export default {
  components: {
    // Footer,
    Header,
    Menu,
  },
  data() {
    return {
      key: 1,
    };
  },
  // mounted() {
  //   this.$store.dispatch('getSummary');
  //   this.$store.dispatch('getComposition');
  // },
  watch: {
    lang() {
      this.key = ++this.key;
    },
  },
  computed: {
    lang() {
      return this.$store.getters.langValue.key;
    },
  },
  destroyed() {
    this.$store.dispatch('setSummary', []);
    // this.$store.dispatch('setComposition', {});
  },
};
</script>

<style>
.main-layout .content-view {
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 60px;
  max-width: 1664px;
  width: 100%;
  margin: 0 auto;
}
.v-application--is-rtl .main-layout .content-view {
  padding-right: 50px;
  padding-left: 60px;
}
@media screen and (max-width: 1440px) {
  .main-layout .content-view {
    padding-left: 20px;
    padding-right: 40px;
  }
  .v-application--is-rtl .main-layout .content-view {
    padding-right: 20px;
    padding-left: 40px;
  }
}
@media screen and (max-width: 999px) {
  .main-layout .content-view {
    padding-top: 20px;
    /* padding-left: 40px; */
    padding-right: 20px;
  }
  .v-application--is-rtl .main-layout .content-view {
    padding-left: 20px;
  }
}
@media screen and (max-width: 599px) {
  .main-layout .content-view {
    padding-left: 16px;
    padding-right: 16px;
  }
  .v-application--is-rtl .main-layout .content-view {
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
