<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout';
import MainLayout from '@/layouts/MainLayout';

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout';
    },
  },
  components: {
    EmptyLayout,
    MainLayout,
  },
};
</script>

<style lang="scss">
body,
#app {
  color: var(--v-black-base);
  background: var(--v-accent-base);
  .v-pagination__navigation i,
  .v-pagination__item.white {
    color: var(--v-black-base) !important;
  }
  .v-pagination__navigation--disabled i,
  .v-pagination__navigation,
  .v-pagination__item {
    box-shadow: none;
    color: var(--v-gray-base) !important;
  }
  .v-pagination__navigation,
  .v-pagination__item {
    background: transparent !important;
  }
  .elevation-8 {
    box-shadow: 0px 4px 8px rgba(104, 104, 104, 0.12) !important;
  }
  * {
    font-family: 'Poppins', sans-serif !important;
  }
  .text-h4 {
    font-size: 2.25rem !important;
  }
  th {
    color: var(--v-black-base);
    font-size: 16px;
    font-weight: 600;
  }
  .primary.v-btn--disabled {
    background-color: #d2ebf2 !important;
    color: white !important;
  }
  .v-text-field--outlined fieldset,
  .v-btn--outlined {
    border: 1px solid #ebeff4 !important;
  }
  .v-input--is-focused fieldset {
    border: 1px solid var(--v-primary-base) !important;
  }
  .v-input--selection-controls__input:hover i {
    color: var(--v-primary-base);
  }
  .v-badge__badge {
    font-size: 10px;
    height: 16px;
    min-width: 16px;
    padding: 3px 5px;
  }
}
* {
  letter-spacing: 0.2px !important;
  line-height: 1.21 !important;
}
a {
  text-decoration: none;
}
.center {
  input {
    font-size: 18px !important;
    text-align: center;
  }
}
.f40 {
  font-size: 2.5rem;
  font-weight: 700;
}
.f30 {
  font-size: 1.875rem;
  font-weight: 700;
}
.f28 {
  font-size: 1.75rem;
  font-weight: 600;
}
.f18 {
  font-size: 1.125rem;
  font-weight: 500;
}
.v-tab {
  text-transform: initial !important;
  font-weight: 600 !important;
}
.v-tabs-slider-wrapper {
  height: 2px !important;
}
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}
.v-data-table__wrapper tbody tr:hover {
  background-color: #ebeff4 !important;
}
.v-data-table-header {
  height: 68px;
  th {
    color: var(--v-gray-base) !important;
    font-weight: 400 !important;
  }
}
.wrap {
  padding-right: 60px;
  padding-left: 50px;
  max-width: 1664px;
  width: 100%;
  margin: 0 auto;
}
.width100 {
  width: 100%;
}
.height100 {
  height: 100%;
}
.opasity40 {
  opacity: 40%;
}
.flag {
  margin-top: 6px;
}
.link {
  cursor: pointer;
}
.logo {
  display: flex;
  align-items: center;
  height: 84px;
  /* margin-left: 24px; */
}
.view-without-scroll {
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.view-without-scroll::-webkit-scrollbar {
  width: 2px;
  background: var(--v-gray-lighten1);
}
.view-without-scroll::-webkit-scrollbar-thumb {
  background: var(--v-black-base);
}
.rotate {
  transform: rotate(-180deg);
}
.white-filter {
  filter: invert(100%);
}
.primary-filter {
  filter: invert(50%) sepia(34%) saturate(2608%) hue-rotate(159deg) brightness(85%) contrast(101%);
}
#app .v-dialog--active,
#app .v-text-field--outlined,
.v-btn {
  border-radius: 10px;
}
.v-btn--tile {
  border-radius: 0;
}
.v-btn--round {
  border-radius: 50%;
}
.v-btn__content {
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
  opacity: 1 !important;
}
.text-capitalize .v-btn__content {
  text-transform: capitalize;
}
.text-body-2 .v-btn__content {
  font-size: 14px;
  font-weight: 400;
}
.text-caption .v-btn__content {
  font-size: 12px;
  font-weight: 400;
}
input::placeholder {
  color: #909ab0 !important;
}
.error--text fieldset {
  border: 1px solid var(--v-black-base) !important;
}
.error--text .v-messages__message {
  color: var(--v-error-base) !important;
}
.v-picker__title__btn,
.v-picker__title__btn div {
  color: white !important;
}
.field48 .v-input__append-inner {
  margin-top: 12px !important;
}
.field56 .v-input__append-inner,
.field56 .v-input__prepend-inner {
  margin-top: 18px !important;
}
.field56 .v-text-field__slot {
  margin-top: 3px !important;
}
.field64 .v-input__append-inner {
  margin-top: 18px !important;
}
.field64 .v-input__prepend-inner {
  margin-top: 22px !important;
}
.field48 .v-select__slot,
.field64 .v-select__slot {
  margin-top: 6px !important;
}
.field64 .v-text-field__slot {
  margin-top: 8px !important;
}
.otp-input input {
  text-align: center;
  font-size: 24px;
}
.otp-input .v-input,
.otp-input .v-input__control {
  max-width: 64px !important;
}
.triangle-top {
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid #219653;
}
.triangle-bottom {
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid var(--v-error-base);
}
.v-slide-group__prev,
.v-slide-group__next {
  display: none !important;
}
.floating-tooltip-2 {
  position: absolute;
  display: none;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: var(--v-black-base);
  text-align: center;
  z-index: 1000;
  pointer-events: none;
  border-radius: 6px !important;
}
.sma-legend {
  position: absolute;
  left: 3px;
  top: 3px;
  z-index: 1000;
  pointer-events: none;
}
@media screen and (max-width: 599px) {
  #app {
    .v-pagination__navigation i,
    .v-pagination__item.white {
      color: var(--v-primary-base) !important;
    }
    .text-h4 {
      font-size: 1.5rem !important;
    }
    .v-tabs-slider-wrapper {
      display: none;
    }
    .v-tab--active {
      color: white !important;
      background: var(--v-primary-base);
      border-radius: 5px;
    }
    .v-tab {
      height: 36px;
    }
  }
  .logo {
    height: 77px;
  }
  .close-btn.gray--text {
    top: inherit;
    bottom: 30px;
  }
  .f30 {
    font-size: 1.5rem;
  }
  .wrap {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
