import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/plan';

export default {
  state: {
    planList: { positions: [] },
    planListHistory: {},
  },
  actions: {
    supportFunctionPlan(context, payload) {
      const data = payload;
      for (let i = 0; i < data.positions.length; i++) {
        data.positions[i].percent_updated ? (data.positions[i].percent_updated_new = data.positions[i].percent_updated / 100) : '';
      }
      context.commit('GET_PLANLIST', data);
    },
    getPlanList(context) {
      return axios.get(`${api}`).then((res) => {
        context.dispatch('supportFunctionPlan', res.data);
      });
    },
    setPlan: (context) => {
      context.commit('GET_PLANLIST', { positions: [] });
    },
    addPlanItem(context, payload) {
      return axios.post(`${api}/positions/`, payload).then((res) => {
        context.dispatch('supportFunctionPlan', res.data);
      });
    },
    updatePlanItem(context, { data, id }) {
      return axios.put(`${api}/positions/${id}`, data).then((res) => {
        context.dispatch('supportFunctionPlan', res.data);
      });
    },
    removePlanItem(context, payload) {
      return axios.delete(`${api}/positions/${payload}`);
    },
    applyPlanItem(context) {
      return axios.post(`${api}/apply`).then((res) => {
        context.dispatch('supportFunctionPlan', res.data);
      });
    },
    discardPlanItem(context) {
      return axios.post(`${api}/discard`).then((res) => {
        context.dispatch('supportFunctionPlan', res.data);
      });
    },
    getPlanListHistory(context, payload) {
      if (`${router.app._route.path}${payload != 1 ? `?page=${payload || 1}` : ''}` != router.app._route.fullPath) {
        router.push(`${router.app._route.path}${payload != 1 ? `?page=${payload || 1}` : ''}`);
      }
      return axios.get(`${api}/history/?page=${payload - 1 || 0}`).then((res) => {
        context.commit('GET_PLANLISTHISTORY', res.data);
      });
    },
    setPlanListHistory: (context) => {
      context.commit('GET_PLANLISTHISTORY', {});
    },
  },
  mutations: {
    GET_PLANLIST: (state, payload) => {
      state.planList = payload;
      return state.planList;
    },
    GET_PLANLISTHISTORY: (state, payload) => {
      state.planListHistory = payload;
      return state.planListHistory;
    },
  },
  getters: {
    planList(state) {
      return state.planList;
    },
    planListHistory(state) {
      return state.planListHistory;
    },
  },
};
