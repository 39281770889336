import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/wallet/';

export default {
  state: {
    walletSummary: {},
    walletStatistics: {},
    walletTransactions: {},
    walletWithdrawal: {},
    walletWithdrawalFee: {},
  },
  actions: {
    getWalletSummary(context) {
      return axios.get(`${api}summary`).then((res) => {
        context.commit('GET_WALLETSUMMARY', res.data);
      });
    },
    getWalletStatistics(context, payload) {
      return axios.get(`${api}statistics?period=${payload}`).then((res) => {
        context.commit('GET_WALLETSTATISTICS', res.data);
      });
    },
    getWalletTransactions(context, payload) {
      if (`${router.app._route.path}${payload != 1 ? `?page=${payload || 1}` : ''}` != router.app._route.fullPath) {
        router.push(`${router.app._route.path}${payload != 1 ? `?page=${payload || 1}` : ''}`);
      }
      return axios.get(`${api}transactions/?page=${payload - 1 || 0}`).then((res) => {
        context.commit('GET_WALLETTRANSACTIONS', res.data);
      });
    },
    getWalletWithdrawalFee(context, payload) {
      return axios.get(`${api}withdrawal-fee/?withdrawal_amount=${payload * 100}`).then((res) => {
        context.commit('GET_WALLETWITHDRAWALFEE', res.data);
      });
    },
    getWalletWithdrawal(context, payload) {
      if (`${router.app._route.path}${payload != 1 ? `?page=${payload || 1}` : ''}` != router.app._route.fullPath) {
        router.push(`${router.app._route.path}${payload != 1 ? `?page=${payload || 1}` : ''}`);
      }
      return axios.get(`${api}withdrawal-requests/?page=${payload - 1 || 0}`).then((res) => {
        context.commit('GET_WALLETWITHDRAWAL', res.data);
      });
    },
    submitWalletWithdrawal(context, payload) {
      return axios.post(`${api}withdrawal-requests/`, payload);
    },
    submitWalletDeposit(context, payload) {
      return axios.post(`${api}deposit/`, payload);
    },
    submitWalletDepositFake(context, payload) {
      return axios.post(`${api}deposit/fake`, payload);
    },
    setWallet: (context) => {
      context.commit('GET_WALLETSUMMARY', {});
      context.commit('GET_WALLETSTATISTICS', {});
      context.commit('GET_WALLETTRANSACTIONS', {});
      context.commit('GET_WALLETWITHDRAWAL', {});
      context.commit('GET_WALLETWITHDRAWALFEE', {});
    },
  },
  mutations: {
    GET_WALLETSUMMARY: (state, payload) => {
      state.walletSummary = payload;
      return state.walletSummary;
    },
    GET_WALLETSTATISTICS: (state, payload) => {
      state.walletStatistics = payload;
      return state.walletStatistics;
    },
    GET_WALLETTRANSACTIONS: (state, payload) => {
      state.walletTransactions = payload;
      return state.walletTransactions;
    },
    GET_WALLETWITHDRAWAL: (state, payload) => {
      state.walletWithdrawal = payload;
      return state.walletWithdrawal;
    },
    GET_WALLETWITHDRAWALFEE: (state, payload) => {
      state.walletWithdrawalFee = payload;
      return state.walletWithdrawalFee;
    },
  },
  getters: {
    walletSummary(state) {
      return state.walletSummary;
    },
    walletStatistics(state) {
      return state.walletStatistics;
    },
    walletTransactions(state) {
      return state.walletTransactions;
    },
    walletWithdrawal(state) {
      return state.walletWithdrawal;
    },
    walletWithdrawalFee(state) {
      return state.walletWithdrawalFee;
    },
  },
};
