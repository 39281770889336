import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import common from './modules/common';
import home from './modules/home';
import profile from './modules/profile';
import market from './modules/market';
import portfolio from './modules/portfolio';
import plan from './modules/plan';
import wallet from './modules/wallet';
import notification from './modules/notification';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    common,
    home,
    profile,
    market,
    portfolio,
    plan,
    wallet,
    notification,
  },
});
