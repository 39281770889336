import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VieMeta from 'vue-meta';
import titlePlugin from '@/plugins/title.plugin';
import Chartist from 'chartist';
import VueClipboard from 'vue-clipboard2';
import i18n from './i18n';

import './assets/scss/style.scss';

Vue.config.productionTip = false;

Vue.use(VieMeta);
Vue.use(titlePlugin);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.prototype.$Chartist = Chartist;

export const eventBus = new Vue();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
