import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/sign-in/';

export default {
  actions: {
    logout() {
      const auth2 = window.gapi.auth2.getAuthInstance();
      auth2.signOut().then(() => {
        console.log('User signed out.');
      });
      sessionStorage.clear();
      router.push('/login');
    },
    signIn(context, payload) {
      return axios.post(`${api}phone`, payload);
    },
    otp(context, payload) {
      return axios.post(`${api}phone/otp`, payload);
    },
    otpResend(context, payload) {
      return axios.post(`${api}phone/otp-resend`, payload);
    },
    social(context, payload) {
      return axios.post(`${api}social`, payload);
    },
  },
};
